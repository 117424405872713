<template>
  <div>
    <div id="forms_div2">
      <div style="margin: 10% auto auto 45%;">
        <el-select v-model="document" clearable placeholder="请选择上报报表" size="small" @change="gotoOnlineDocument()">
          <el-option
              v-for="item in documents"
              :key="item.id"
              :label="item.documentName"
              :value="item.documentUrl"
          />
        </el-select>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/views/header";
import {mapGetters} from "vuex";
import {Decrypt, Encrypt} from '@/utils/secret';

export default {
  components: {Header},
  name: "pro_online_document",
  data(){
    return{
      document: '',
      documents: []
    }
  },
  computed: {
    ...mapGetters(['GET_BDST'])
  },
  created() {
    if (!this.$route.params.key){
      this.$router.push("/manage")
    }
    this.getOnlineDocument();
  },
  methods:{
    getOnlineDocument(){
      this.axios.post('/onlineDocument/getOnlineDocument', (response) => {
        this.documents = response.obj;
      },{
        depart_id: 7,
        bdst: this.GET_BDST
      })
    },
    gotoOnlineDocument(){
      this.$router.push({
        name: 'View_online',
        params: {
          online_document_url: Decrypt(this.document),
          flag: 2
        }
      });
    }
  }
}
</script>

<style scoped>
#admindiv1{
  border-radius: 5px;
  background-color: white;
  display: flex;
  width: 95%;
  height: 40px;
  margin: 10px auto 30px;
  justify-content: space-between;
}
#forms_div2{
  border-radius: 5px;
  margin: 0 auto;
  background-color: white;
  width: 95%;
  padding-top: 20px;
  padding-bottom: 20px;
  min-height: 500px;
}
</style>

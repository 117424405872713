<template>
  <div>
    <div style="margin-top: 20%">
      <el-tag style="margin-right: 5%">&nbsp;表&nbsp;&nbsp;&nbsp;&nbsp;格&nbsp;</el-tag>
      <el-select v-model="file" clearable placeholder="请选择表格" size="small" style="width: 80%;" @change="get_oper_name">
        <el-option
            v-for="item in files"
            :key="item.fileToken"
            :label="item.documentName"
            :value="item.fileToken"
        />
      </el-select>
    </div>
    <div style="display: flex;width: 93%;margin-top: 2%">
      <el-tag>在第</el-tag>
      <el-input size="small" v-model="old_row"/>
      <el-tag>行下面</el-tag>
      <el-select v-model="flag" clearable placeholder="" size="small" style="width: 30%;">
        <el-option
            v-for="item in flags"
            :key="item.value"
            :label="item.label"
            :value="item.value"
        />
      </el-select>
      <el-input size="small" v-model="add_row"/>
      <el-tag>行</el-tag>
    </div>
    <div style="margin-top: 5%;margin-left: 40%">
      <el-button type="danger" size="small" @click="yes_update_row">确认修改</el-button>
    </div>
  </div>
</template>

<script>
import {ElMessage} from "element-plus";
import {mapGetters} from "vuex";

export default {
  name: "index",
  computed: {
    ...mapGetters(['GET_BDST'])
  },
  inject: ['reload'],
  data() {
    return {
      file: '',
      files: [],
      add_row: '',
      old_row: '',
      flag: '',
      flags: [
        {
          value: '0',
          label: '插入',
        },
        {
          value: '1',
          label: '删除',
        },
      ]
    }
  },
  created() {
    this.get_file_name()
  },
  methods: {
    get_file_name(){
      this.axios.post('/onlineDocument/getFileByDepartBdst', (response) => {
        this.files = response.obj
      },{
        depart_id: 7,
        bdst: this.GET_BDST
      })
    },
    yes_update_row(){
      if (this.flag === '' || this.file === '' || this.old_row === '' || this.add_row === ''){
        ElMessage.error('有信息未填写完整，请检查!')
      }else {
        this.axios.post('/operDispose/updateByFileToken', (response) => {
          if (response.obj){
            ElMessage({
              message: '修改成功!',
              type: 'success'
            })
            this.reload()
          }else {
            ElMessage.error('修改失败，请联系科信部!')
          }
        },{
          file_token: this.file,
          old_row: this.old_row,
          add_row: this.add_row,
          flag: this.flag
        })
      }
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <div>
  <!-- 根据工点查询 -->
    <div>
      <el-input v-model="oper_name" clearable placeholder="请输入作业面名称" @input="get_dispose"/>
    </div>
  <!--  配置表-->
    <div>
      <el-table :data="disposes" style="width: 100%">
        <el-table-column prop="operName" label="作业面名称" width="480"/>
        <el-table-column prop="a" label="作业类型" width="180">
          <template #default="scope">
            <el-tag type="success" v-if="scope.row.sdgxName === '1'">开挖</el-tag>
            <el-tag type="success" v-if="scope.row.sdgxName === '2'">仰拱</el-tag>
            <el-tag type="success" v-if="scope.row.sdgxName === '3'">二衬</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="startEnd" label="表格配置行" width="180"/>
        <el-table-column label="操作">
          <template #default="scope">
            <el-button type="primary" @click="update_dia(scope.row)"> 修改 </el-button>
            <el-button type="danger" @click="update_remove_dispose(scope.row, 0)"> 删除 </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--  分页-->
    <div style="float: right;margin-top: 20px;">
      <el-pagination v-model:currentPage="pagenum"
                     :small="true"
                     background layout="prev, pager, next" :total="totals">
      </el-pagination>
    </div>
  <!--  修改-->
    <div>
      <el-dialog
          v-model="diaChangeRow"
          title="新增材料"
          width="30%"
          :before-close="handleCloseChangeRow">
        <div>
          <el-input clearable v-model="change_row" placeholder="请输入所在行"/>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button type="primary " @click="update_remove_dispose(dispose_id, 1)">提交</el-button>
          </span>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {ElMessage} from "element-plus";
import {mapGetters} from "vuex";

export default {
  name: "index",
  computed: {
    ...mapGetters(['GET_BDST'])
  },
  inject: ['reload'],
  data() {
    return {
      disposes: [],
      oper_name: '',
      totals: 0,
      pagenum: 1,
      diaChangeRow: false,
      dispose_id: '',
      change_row: ''
    }
  },
  created() {
    this.get_dispose()
  },
  methods: {
    get_dispose(){
      this.axios.get('/operDispose/getDispose', (response) => {
        this.disposes = response.obj.records
        this.totals = response.obj.pages * 10
      },{
        oper_name: this.oper_name,
        bdst: this.GET_BDST,
        pageCount: 1
      })
    },
    update_remove_dispose(item, item2){
      this.axios.post('/operDispose/removeUpdateDispose', (response) => {
        if (response.obj){
          ElMessage({
            message: '操作成功',
            type: 'success'
          })
          this.reload()
        }else {
          ElMessage.error("删除失败!")
        }
      },{
        flag: item2,
        id: item.id,
        row: this.change_row
      })
    },
    update_dia(item){
      this.change_row = item.startEnd
      this.dispose_id = item
      this.diaChangeRow = true
    },
    handleCloseChangeRow(){
      this.diaChangeRow = false
      this.change_row = ''
    }
  },
  watch:{
    pagenum(){
      this.axios.get('/operDispose/getDispose', (response) => {
        this.disposes = response.obj.records
        this.totals = response.obj.pages * 10
      },{
        oper_name: this.oper_name,
        bdst: this.GET_BDST,
        pageCount: this.pagenum
      })
    }
  }
}
</script>

<style scoped>

</style>

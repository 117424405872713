<template>
  <div>
    <div>
      <Header></Header>
    </div>
    <div id="admindiv1">
      <div style="margin-top: 15px;font-family: '苹方',serif;font-size: 13px;margin-left: 10px">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item style="font-size: 13px" :to="{ path: '/manage' }" @click="reall()">首页</el-breadcrumb-item>
          <el-breadcrumb-item style="font-size: 13px">在线文档管理</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>

    <div id="forms_div2">
      <el-tabs v-model="activeName" tab-position="left" @tab-click="handleClick">
        <el-tab-pane label="报表上报" name="a"><Pro_online_document v-if="a" /></el-tab-pane>
        <el-tab-pane label="报表配置" name="b"><File_dispose v-if="b" /></el-tab-pane>
        <el-tab-pane label="配置查询" name="c"><Update_file_dispose v-if="c" /></el-tab-pane>
	      <el-tab-pane label="进度上传" name="d"><Upload_day_schedule v-if="d" /></el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>

import Header from "@/views/header";
import Pro_online_document from "@/views/procoll/online_document/online_docu_bdst/pro_online_document";
import File_dispose from "@/views/online_document/dispose/file_dispose";
import Update_file_dispose from "@/views/online_document/dispose/update_file_dispose";
import Upload_day_schedule from "@/views/procoll/large_screen/upload_day_schedule.vue";


export default {
  components: {Header, Pro_online_document, File_dispose, Update_file_dispose, Upload_day_schedule},
  name: "online_document",
  data(){
    return{
      activeName: 'a',
	    a: true,
	    b: false,
	    c: false,
	    d: false
    }
  },
  created() {
    if (!this.$route.params.key){
      this.$router.push("/manage")
    }
    if (sessionStorage.getItem('pro_online')){
      this.activeName = sessionStorage.getItem('pro_online');
	    if (this.activeName === 'a'){
		    this.a = true
		    this.d = false
		    this.b = false
		    this.c = false
	    }
	    if (this.activeName === 'b'){
		    this.b = true
		    this.d = false
		    this.a = false
		    this.c = false
	    }
	    if (this.activeName === 'c'){
		    this.c = true
		    this.d = false
		    this.b = false
		    this.a = false
	    }
	    if (this.activeName === 'd'){
		    this.d = true
		    this.a = false
		    this.b = false
		    this.c = false
	    }
    }
  },
  methods:{
    handleClick(tab, event){
      //console.log(tab)
      // 点击tab后触发事件，修改显示页面，将状态保存在sessionStorage里面
      sessionStorage.setItem('pro_online', tab.props.name)
	    if (tab.props.name === 'a'){
		    this.a = true
		    this.d = false
		    this.b = false
		    this.c = false
	    }
	    if (tab.props.name === 'b'){
		    this.b = true
		    this.d = false
		    this.a = false
		    this.c = false
	    }
	    if (tab.props.name === 'c'){
		    this.c = true
		    this.d = false
		    this.b = false
		    this.a = false
	    }
	    if (tab.props.name === 'd'){
		    this.d = true
		    this.a = false
		    this.b = false
		    this.c = false
	    }
    },
    reall(){
      sessionStorage.removeItem('pro_online');
    },
  }
}
</script>

<style scoped>
#admindiv1{
  border-radius: 5px;
  background-color: white;
  display: flex;
  width: 95%;
  height: 40px;
  margin: 10px auto 30px;
  justify-content: space-between;
}

#forms_div2{
  border-radius: 5px;
  margin: 0 auto;
  background-color: white;
  width: 95%;
  padding-top: 20px;
  padding-bottom: 20px;
  min-height: 500px;
}
</style>

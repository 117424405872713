<template>
  <div>
    <div style="display: grid;grid-template-columns: 1fr 1fr">
      <div>
        <div>
          <!--表格选择-->
          <div style="margin-top: 2%">
            <el-tag style="margin-right: 5%">&nbsp;表&nbsp;&nbsp;&nbsp;&nbsp;格&nbsp;</el-tag>
            <el-select v-model="file" clearable placeholder="请选择表格" size="small" style="width: 80%;" @change="get_oper_name">
              <el-option
                  v-for="item in files"
                  :key="item.fileToken"
                  :label="item.documentName"
                  :value="item.fileToken"
              />
            </el-select>
          </div>
          <!--工点选择-->
          <div style="margin-top: 10%">
            <el-tag style="margin-right: 5%">&nbsp;工&nbsp;&nbsp;&nbsp;&nbsp;点&nbsp;</el-tag>
            <el-select v-model="oper" clearable placeholder="请选择工点" size="small" style="width: 80%;">
              <el-option
                  v-for="item in opers"
                  :key="item.id"
                  :label="item.operName"
                  :value="item.id"
              />
            </el-select>
          </div>
          <!--开挖部位选择-->
          <div style="margin-top: 10%">
            <el-tag style="margin-right: 5%">&nbsp;部&nbsp;&nbsp;&nbsp;&nbsp;位&nbsp;</el-tag>
            <el-select v-model="body" clearable placeholder="请选择部位" size="small" style="width: 80%;">
              <el-option
                  v-for="item in bodys"
                  :key="item.label"
                  :label="item.label"
                  :value="item.value"
              />
            </el-select>
          </div>
          <!--单元格选择-->
          <div style="margin-top: 10%;display: flex">
            <el-tag style="margin-right: 5%">&nbsp;单&nbsp;元&nbsp;行&nbsp;</el-tag>
            <el-input size="small" v-model="row" placeholder="行" style="width: 80%"/>
          </div>
        </div>
        <!--  提交-->
        <div style="margin-top: 5%">
          <el-button type="primary" style="float: right;margin-right: 55%" @click="add_dispose">提交新配置</el-button>
        </div>
      </div>

      <!--表格行改变-->
      <div style="margin-top: 2%">
        <Up_file_row></Up_file_row>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/views/header";
import {ElMessage} from "element-plus";
import {mapGetters} from "vuex";
import Up_file_row from "@/views/online_document/dispose/up_file_row";

export default {
  name: "file_dispose",
  computed: {
    ...mapGetters(['GET_BDST'])
  },
  components: {Header, Up_file_row},
  data(){
    return{
      file: '',
      files: [],
      oper: '',
      opers: [],
      body: '',
      bodys: [
        {
          value: '1',
          label: '开挖',
        },
        {
          value: '2',
          label: '仰拱',
        },
        {
          value: '3',
          label: '二衬',
        }
      ],
      row: '',
      col: ''
    }
  },
  created() {
    this.get_file_name()
  },
  methods:{
    get_file_name(){
      this.axios.post('/onlineDocument/getFileByDepartBdst', (response) => {
        this.files = response.obj
      },{
        depart_id: 7,
        bdst: this.GET_BDST
      })
    },
    get_oper_name(){
      this.axios.post('/operName/getOperByBdst', (response) => {
        this.opers = response.obj
      },{
        bdst: this.GET_BDST
      })
    },
    add_dispose(){
      this.axios.post('/operDispose/saveDispose', (response) => {
        if (response.obj){
          ElMessage({
            message: '新增配置成功',
            type: 'success'
          })
          this.row = ''
          this.col = ''
          this.body = ''
        }else {
          ElMessage.error('新增失败，请联系科信部!')
        }
      },{
        bdst: this.GET_BDST,
        oper_name: this.oper,
        file_token: this .file,
        sdgx_name: this.body,
        start_end: this.row * 1 - 1
      })
    }
  }
}
</script>

<style scoped>
#admindiv1{
  border-radius: 5px;
  background-color: white;
  display: flex;
  width: 95%;
  height: 40px;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  justify-content: space-between;
}
</style>

<template>
	<div>
	<!--	数据日期-->
		<div>
			<el-date-picker
				v-model="data_date"
				type="date"
				placeholder="请选择日期"
				format="YYYY-MM-DD"
				value-format="YYYY-MM-DD"
			/>
		</div>
	<!--上传-->
		<div style="margin-left: 35%;margin-top: 10%">
			<el-upload
				ref="upload"
				class="upload-demo"
				action="https://www.cr12cz.cn/czscsms/up/upload"
				:auto-upload="false"
				:data="dataup"
				:on-change="imgchange"
				:on-remove="handleRemove"
				:multiple="false"
				:limit="1"
			>
				<template #trigger>
					<el-button type="primary" style="width: 480px">请选择进度表</el-button>
				</template>
				<div style="margin-top: 10%">
					<el-button
						style="width: 480px;"
						type="success"
						@click="uploadFile"
					>上传
					</el-button>
				</div>
			</el-upload>
		</div>
	</div>
</template>

<script>
import {ElMessage} from "element-plus";
import {mapGetters} from "vuex";
import VXETable from "vxe-table";
import {setNowDate} from "@/utils/time";

export default {
	name: "",
	inject: ['reload'],
	computed: {
		...mapGetters(['GET_BDST']),
		...mapGetters(['GET_USER'])
	},
	data() {
		return {
			data_date: '',
			fileList: [],
			dataup: {
				file: '',
				userid: ''
			}
		}
	},
	created() {
		this.data_date = setNowDate(new Date())
	},
	methods: {
		imgchange(file) {
			this.fileList.push(file);
		},
		handleRemove(file) {
			for (let i = 0; i < this.fileList.length; i++) {
				if (this.fileList[i].raw.lastModified === file.raw.lastModified) {
					this.fileList.splice(i, 1)
				}
			}
		},
		uploadFile(){
			this.dataup.file = this.fileList[0].raw;
			this.axios.post('/daySchedule/uploadSchedule', (response) => {
				if (response.obj){
					ElMessage.success('上传成功!')
					this.reload()
				}else {
					ElMessage.error('上传失败，请联系科信部!')
				}
			},{
				file: this.dataup.file,
				data_date: this.data_date
			})
		}
	}
}
</script>

<style>

</style>